import { Controller } from "@hotwired/stimulus";
import { loading } from "../utils/_loading"

class RequestError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
    this.message = message;
  }
}

export default class extends Controller {
  static targets = ['form', 'content', 'selectionForm'];

  connect() {}

  triggerForm(_e) {
    this.contentTarget.classList.add('p-5');
    this.contentTarget.innerHTML = loading();

    fetch('/api/v1/sessions/fetch_form', {
      method: "GET"
    })
    .then(response => response.json())
    .then(result => {
      this.contentTarget.classList.remove('p-5');
      this.contentTarget.innerHTML = result.data.html;
    })
  }

  submitForm(_e) {
    const form = new FormData(this.formTarget);
    const url  = this.formTarget.action;

    this.contentTarget.classList.add('p-5');
    this.contentTarget.innerHTML = loading();

    fetch(url, {
      method: "POST",
      body: form
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      throw new RequestError('Invalid email or password', response);
    })
    .then(result => {
      if(result.data.redirect_url) {
        window.location.href = result.data.redirect_url;
      } else {
        this.contentTarget.classList.remove('p-5');
        this.contentTarget.innerHTML = result.data.html;
      }
    })
    .catch(error=> error.response.json())
    .then(response => {
      this.contentTarget.classList.remove('p-5');
      this.contentTarget.innerHTML = response.data.html;
    })
    .catch(_error => {
      return;
    })
  }

  submitSelectForm(_e) {
    const form = new FormData(this.selectionFormTarget);
    const url  = this.selectionFormTarget.action;

    fetch(url, {
      method: "POST",
      body: form
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      throw new RequestError('Invalid email or password', response);
    })
    .then(result => {
      if(result.data.redirect_url) {
        window.location.href = result.data.redirect_url;
      }
    })
  }
}
