const loading = (loadingText = "", size = 'm') => {
  return `
    <div class="page-loader d-flex justify-content-center align-items-center flex-column">
      <div>
        <div class="spinner-border ${size === 'sm' ? 'spinner-border-sm' : '' }" role="status">
          <span class="visually-hidden">${loadingText}</span>
        </div>
      </div>
      <div class="page-loader-text">${loadingText}</div>
    </div>`;
}

export { loading };
