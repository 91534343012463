import { Controller } from "@hotwired/stimulus";
import { loading } from "../utils/_loading";
import { GET_SS_STATE } from "../utils/_sessionstorage";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'deliveryDetails',
    'collectionDetails',
    'deliverySwitch',
    'collectionSwitch',
    'orderSummary'
  ];

  connect() {
    this.initializeToggles();
  }

  initializeToggles() {

  }

  toggleAddressDetails(fulfillmentType) {
    if (fulfillmentType === 'delivery') {
      if (this.hasDeliveryDetailsTarget) {
        this.deliveryDetailsTarget.classList.remove('d-none');
      }

      if (this.hasCollectionDetailsTarget) {
        this.collectionDetailsTarget.classList.add('d-none');
      }
    } else {
      if (this.hasCollectionDetailsTarget) {
        this.collectionDetailsTarget.classList.remove('d-none');
      }

      if (this.hasDeliveryDetailsTarget) {
        this.deliveryDetailsTarget.classList.add('d-none');
      }
    }
  }

  toggleFulfillmentDetails(e) {
    this.toggleAddressDetails(e.currentTarget.value);

    this.orderSummaryTarget.classList.add('pb-5');
    this.orderSummaryTarget.innerHTML = loading('');

    const form      = new FormData();
    const sessionId = document.querySelector('[data-order-session-id]').dataset.orderSessionId;
    const cartItems = JSON.parse(GET_SS_STATE(sessionId)).cart;

    form.append('products', JSON.stringify(cartItems));
    form.append('fulfillment_type', e.currentTarget.value);

    Rails.ajax({
      type: 'post',
      dataType: 'json',
      url: '/api/v1/point_of_sale/cost_calculator',
      data: form,
      success: (response) => {
        this.orderSummaryTarget.classList.remove('pb-5');
        this.orderSummaryTarget.innerHTML = response.data.summary_html;
      },
      error: (e) => {
        console.error('error', e);
      },
    });
  }

}
