import { Controller } from "@hotwired/stimulus";
import Uppy                from '@uppy/core';
import Dashboard           from '@uppy/dashboard';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload';
import ImageEditor         from '@uppy/image-editor';

// const Uppy                = require('@uppy/core');
// const Dashboard           = require('@uppy/dashboard');
// const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload');
// const ImageEditor         = require('@uppy/image-editor');

export default class extends Controller {
  static targets = [
    'file',
    'documentUploading',
    'documentUploadingSvg',
    'documentMainContent',
    'uploadable'
  ]

  oneMegabyte = 1048576;

  connect() {
    this.uploadableTargets.forEach(target => {
      const id            = target.dataset.uploadableName;
      const aspectRatio   = target.dataset.uploadableAspectRatio;
      const uppyEl        = target.querySelector('[data-uppy]');
      const maxNumFiles   = Number(target.dataset.uploadableMaxNumFiles || 1);
      const currentImages = Array.from(target.querySelectorAll('img')).filter(item => !Array.from(item.classList).includes('wui-fallback-image'));
      const autoSubmit    = target.dataset.uploadableAutoSubmit === 'true';
      // const isProduction  = document.querySelector("meta[name='environment']").getAttribute("content") === 'production';

      const uppyInst = new Uppy({
        debug:                      false,
        autoProceed:                false,
        allowMultipleUploads:       false,
        allowMultipleUploadBatches: false,
        restrictions: {
                                    maxNumberOfFiles: maxNumFiles,
                                    maxFileSize:      5 * this.oneMegabyte,
                                    maxTotalFileSize: maxNumFiles * 5 * this.oneMegabyte,
        },
        allowedFileTypes:           ['image/*']
      });


      // if (currentImages && false) {
      //   uppyInst.on('dashboard:modal-open', () => {
      //     currentImages.forEach((img) => {
      //       fetch(img.src)
      //         .then(res => res.blob())
      //         .then((blob) => {
      //           let uploadId = uppyInst.addFile({
      //             name: img.dataset.fileName,
      //             type: blob.type,
      //             data: blob
      //           });

      //           uppyInst.setFileState(uploadId, {
      //             progress: { uploadComplete: true, uploadStarted: true }
      //           });
      //         })
      //     });
      //   });
      // }

      this.setupUppy(uppyEl, id, aspectRatio, uppyInst, autoSubmit);
    });
  }

  disconnect() {
    if (this.uppy)
      this.uppy.close({ reason: 'disconnected' })
  }

  setupUppy(el, id, aspectRatio, uppyInst, autoSubmit) {
    let defaultAR       = "1/1";
    let trigger         = el.querySelector('[data-behavior="uppy-trigger"]');
    let form            = el.closest('form');
    let directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content");
    let fieldName       = el.dataset.uppy;

    if (trigger) {
      trigger.addEventListener('click', (e) => e.preventDefault());
    }

    if (directUploadUrl)
      uppyInst.use(ActiveStorageUpload, {
        id: `ActiveStorageUpload - ${id}`,
        directUploadUrl: directUploadUrl
      });

    if (trigger) {
      uppyInst.use(Dashboard, {
        id: `Dashboard - ${id}`,
        trigger: `#${trigger.id}`,
        closeAfterFinish: true,
        allowMultipleUploadBatches: false,
        autoOpenFileEditor: false,
        allowMultipleUploads: false
      });
    }

    let [aspectRatioX, aspectRatioY] = this.extractAspectRatioFrom(aspectRatio || defaultAR);
    const ratioFractional            = Number(aspectRatioX / aspectRatioY);

    let cropperOptions = {
      viewMode:           2,
      background:         true,
      autoCropArea:       1,
      initialAspectRatio: ratioFractional,
      cropBoxMovable:     true,
      cropBoxResizable:   true,
      guides:             true,
      center:             true
    }


    uppyInst.use(ImageEditor, {
      id: `ImageEditor - ${id}`,
      target: Dashboard,
      quality: 0.5,
      cropperOptions,
      actions: {
        revert:                 false,
        rotate:                 false,
        granularRotate:         false,
        flip:                   false,
        zoomIn:                 false,
        zoomOut:                false,
        cropSquare:             ratioFractional === 1,
        cropWidescreen:         ratioFractional === Number(16/9),
        cropWidescreenVertical: false,
      }
    })

    uppyInst.on('complete', (result) => {
      el.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element));

      result.successful.forEach((file) => {
        this.appendUploadedFile(el, file, fieldName);
        this.setPreview(el, file);
      });

      uppyInst.cancelAll();

      if (autoSubmit) {
        this.showLoading(el, id);
        form.submit();
      }
    });
  }

  appendUploadedFile(element, file, fieldName) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);

    element.appendChild(hiddenField);
  }

  setPreview(element, file) {
    const defaultImageUrl = document.querySelector('[data-default-image-url]').dataset.defaultLogoUrl;
    let preview           = element.querySelector('[data-behavior="uppy-preview"]');

    if (preview) {
      let src = (file.preview) ? file.preview : defaultImageUrl
      preview.src = src
    }
  }

  showLoading(_el, id) {
    this.documentUploadingSvgTarget.innerHTML = this.lottieFileUploading();
    const container = this.documentMainContentTargets.find(i => i.dataset.name === id);
    if (container)
      container.classList.add('d-none');
    this.documentUploadingTarget.classList.remove('d-none');
  }

  lottieFileUploading() {
    return ' \
      <lottie-player \
        src="/vendor/lottie/doc_upload.json" \
        background="transparent" \
        speed="1" \
        loop="true" \
        style="width: 100%; height: 300px;" \
        autoplay \
      ></lottie-player>';
  }

  enableSubmit() {
    const button = document.querySelector('[data-submit]');
    button.disabled = false;

    document.querySelector('[data-save-notice]').classList.remove('d-none');
  }

  extractAspectRatioFrom(string) {
    return string.split("/");
  }
}
