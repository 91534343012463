import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import { Html5Qrcode } from "html5-qrcode";
import { loading } from "../utils/_loading";


export default class extends Controller {
  static targets = [
    'success',
    'failure',
    'failureReason',
    'reader',
    'output',
    'switch',
    'noDevice',
    'deviceLoadingList',
    'deviceListWrapper',
    'originalState'
  ];
  static values = {
    patron:   String,
    url:      String,
    scanType: String
  }

  initialize() {
    this.html5QrCode = new Html5Qrcode("reader");
    this.devices = [];
    this.cameraId = null;

    this.clonedOriginalState = this.originalStateTarget.cloneNode(true);
  }

  connect() {
    if (this.html5QrCode.getState() === 1) {
      this.getDeviceCameras();
    }
  }

  disconnect() {
    if ([2,3].includes(this.html5QrCode.getState())) {
      this.html5QrCode.stop().then(() => {
        this.devices = [];
        this.cameraId = null;
        console.info('[UJU Scanner] Camera disconnected!');
      }).catch((err) => {
        console.error('[UJU Scanner]', err);
      });
    }
  }

  getDeviceCameras() {
    Html5Qrcode.getCameras()
      .then((devices) => {
        this.devices = devices;

        this.setCameraAndStart(this.devices[this.devices.length - 1]["id"]);
        this.deviceLoadingListTarget.classList.add("d-none");
        this.switchTarget.classList.remove("d-none");

      })
      .catch(_error => {
        this.switchTarget.classList.add("d-none");
        this.deviceListWrapperTarget.classList.add("d-none");
        this.noDeviceTarget.classList.remove("d-none");
      })
  }

  setCameraAndStart(id) {
    this.deviceListWrapperTarget.classList.add("d-none");
    this.cameraId = id;
    this.startCapture();
  }

  switchCamera(event) {
    event.preventDefault();
    if (this.devices.length < 2) { return }

    this.html5QrCode.stop().then(() => {
      const otherDevice = this.devices.find(d => d["id"] !== this.cameraId);
      this.cameraId = otherDevice["id"]
      this.startCapture();
    })
  }

  startCapture() {
    this.readerTarget.classList.remove('d-none');

    this.html5QrCode
      .start(
        { deviceId: { exact: this.cameraId } },
        {
          fps: 30,
          qrbox: 250,
        },
        (qrCodeMessage) => this.onScanSuccess(qrCodeMessage),
        (error) => this.onScanFailure(error)
      )
      .then(() => {
        console.info('[UJU Scanner] Camera connected!');
      })
      .catch((_err) => {});
  }

  onScanSuccess(qrCodeMessage) {
    this.html5QrCode.stop().then(() => {
      this.html5QrCode.clear();
      let codeData;

      var form = new FormData();

      switch (this.scanTypeValue) {
        case "rewards":
          codeData = {
            reward_code: qrCodeMessage,
            patron: this.patronValue,
          };
          break;

        case "event_registration":
          codeData = {
            event_registration_purchase: qrCodeMessage,
          };
          break;

        default:
          codeData = {}; // Optional: Handle unknown scan types
          break;
      }

      form.append(this.scanTypeValue, JSON.stringify(codeData));

      Rails.ajax({
        type:     'post',
        dataType: 'json',
        url: this.urlValue,
        data: form,
        success: (response) => {
          this.switchTarget.classList.add('d-none');
          this.readerTarget.classList.add('d-none');
          this.successTarget.classList.remove('d-none');
          if (response.data) {
            this.successTarget.insertAdjacentHTML( 'afterbegin', response.data );
          }
        },
        error: (response) => {
          this.switchTarget.classList.add('d-none');
          this.readerTarget.classList.add('d-none');
          this.failureTarget.classList.remove('d-none');
          if (response.data.error) {
            this.failureReasonTarget.innerHTML = `<span class="badge rounded-pill bg-danger">${response.data.error}</span>`;
          }
        },
      });
    });
  }

  onScanFailure(_error) { }

  scanAgain(event) {
    event.preventDefault();
    this.successTarget.classList.add('d-none');
    this.failureTarget.classList.add('d-none');
    this.switchTarget.classList.remove('d-none');
    this.startCapture();
  }
}
