import { Controller } from "@hotwired/stimulus";
import { loading } from '../../utils/_loading';
import isEmpty from '../../utils/_empty';

export default class extends Controller {
  static targets = [
    'form',
    'actions'
  ];
  // static values  = { calendarUrl: String, merchant: String, slug: String }

  connect() {
    document.body.classList.add('uju-embedded-form');
    const formContent = this.formTarget;
    let filtered = [];
    let allInputFields = [];
    let initialFieldColour;
    const token = document.querySelector('[data-controller="iframe--form"]').dataset.token;

    this.collectAllInputs(allInputFields, this.scope);
    this.collectInputs(filtered, this.scope);
    this.collectTextareas(filtered, this.scope);

    // Remove required so we can do a manual check
    filtered.forEach(field => (field.required = false));

    let form = document.querySelector('[data-question-validatable]');

    if (![null, undefined].includes(form)) {
      const submitButton = form.querySelector('input[type="submit"]');

      if (submitButton) {
        submitButton.removeAttribute('data-disable-with');
      }

      form.addEventListener("submit", (e) => {
        e.preventDefault();

        let clickable;
        let errors = [];
        initialFieldColour = 'rgba(0,0,0,0.08)';

        filtered.forEach((field) => {
          if (isEmpty(field.value)) {
            const accordionItem     = field.closest('.accordion-item');
            clickable               = accordionItem.querySelector('.accordion-button');
            field.style.borderColor = 'red';

            errors.push([field, clickable]);
          } else {
            field.style.borderColor = initialFieldColour;
          }
        });

        if (errors.length > 0) {
          const htmlBtn = errors[0][1];
          if (htmlBtn.classList.contains('collapsed')) {
            htmlBtn.click();
          }
        }

        if (this.readyToSubmit(errors)) {
          formContent.innerHTML = loading('Preparing ...');
          formContent.style.minHeight = '200px';

          const url = new URL(form.action);
          url.searchParams.append('access_type', 'iframe');

          const formData = new FormData(form);

          allInputFields.forEach((field) => {
            switch(field.type) {
              case 'radio':
                if (field.checked) {
                  formData.append(field.name, field.value);
                }
                break;
              case 'checkbox':
                if (field.checked) {
                  formData.append(field.name, field.value);
                }
                break;
              default:
                formData.append(field.name, field.value);
                break;
            }
          })

          fetch(url, {
            method: "POST",
            body: formData,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
          })
          .then(response => response.json())
          .then(result => {
            formContent.innerHTML = result.data.html;
            this.actionsTarget.classList.add('d-none');
          })
          .catch((result) => {
            formContent.innerHTML = result.data.html;
          })
        }
      });
    }
  }

  readyToSubmit = (errors) => {
    return errors.length === 0;
  }

  collectAllInputs = (collection, scope) => {
    if (scope.element) {
      scope.element.querySelectorAll('input').forEach((field) => {
        if(!['hidden', 'submit', 'checkbox', 'radio'].includes(field.type)) {
          collection.push(field);
        }
      });

      // URL
      scope.element.querySelectorAll('input[type="url"]').forEach((field) => {
        collection.push(field);
      });

      // NUMBERS
      scope.element.querySelectorAll('input[type="number"]').forEach((field) => {
        collection.push(field);
      });

      // CHECKBOX
      scope.element.querySelectorAll('input[type="checkbox"]').forEach((field) => {
        collection.push(field);
      });

      // RADIO
      scope.element.querySelectorAll('input[type="radio"]').forEach((field) => {
        collection.push(field);
      });

      // TEXT AREAS
      scope.element.querySelectorAll('textarea').forEach((field) => {
        collection.push(field);
      });

      // SELECT FIELDS
      scope.element.querySelectorAll('select').forEach((field) => {
        collection.push(field);
      });
    }
  }

  collectInputs = (collection, scope) => {
    if (scope.element) {
      scope.element.querySelectorAll('input').forEach((field) => {
        if(!['hidden', 'submit'].includes(field.type) && field.required) {
          collection.push(field);
        }
      });
    }
  }

  collectTextareas = (collection, scope) => {
    if (scope.element) {
      scope.element.querySelectorAll('textarea').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });
    }
  }
}
