import { Controller } from "@hotwired/stimulus";
import * as CookieConsent from "vanilla-cookieconsent";
import en from "./cookie_consent/translations/en";

export default class extends Controller {
  connect() {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    CookieConsent.run({
      // root: 'body',
      // autoShow: true,
      // disablePageInteraction: true,
      // hideFromBots: true,
      // mode: 'opt-in',
      // revision: 0,

      cookie: {
        name: '_uju_consent_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
      guiOptions: {
        consentModal: {
          layout: 'box wide',
          position: 'bottom right',
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: false
        }
      },

      // Keeping these for debug purposes
      onFirstConsent: ({cookie}) => {},

      onConsent: ({cookie}) => {},

      onChange: ({changedCategories, changedServices}) => {},

      onModalReady: ({modalName}) => {},

      onModalShow: ({modalName}) => {},

      onModalHide: ({modalName}) => {},

      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        analytics: {
          enabled: true,
          // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
          // services: {
          //   uju_analytics: {
          //     label: 'Uju Analytics',
          //     onAccept: () => {},
          //     onReject: () => {}
          //   }
          // }
        },
        ads: {}
      },

      language: {
        default: 'en',
        translations: {
          en: en
        }
      }
    });
  }
}
