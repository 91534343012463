import { Controller } from "@hotwired/stimulus";
import isEmpty from "../utils/_empty";

export default class extends Controller {
  static targets = [];

  connect() {
    let filtered = [];
    let initialFieldColour;

    this.collectAllInputs(filtered, this.scope);

    // Remove required so we can do a manual check
    filtered.forEach(field => (field.required = false));

    const form = document.querySelector('[data-question-validatable]');

    if (form !== null || form !== undefined) {
      const submitButton = form.querySelector('input[type="submit"]');

      if (submitButton) {
        submitButton.removeAttribute('data-disable-with');
      }

      form.addEventListener("submit", function(e) {
        e.preventDefault();

        let clickable;
        let errors = [];
        initialFieldColour = 'rgba(0,0,0,0.08)';

        filtered.forEach((field) => {
          if (isEmpty(field.value)) {
            const accordionItem     = field.closest('.accordion-item');
            clickable               = accordionItem.querySelector('.accordion-button');
            field.style.borderColor = 'red';

            errors.push([field, clickable]);
          } else {
            field.style.borderColor = initialFieldColour;
          }
        });

        if (errors.length > 0) {
          const htmlBtn = errors[0][1];
          if (htmlBtn.classList.contains('collapsed')) {
            htmlBtn.click();
          }
        }

        if (errors.length === 0) {
          form.submit();
        }
      });
    }
  }

  collectAllInputs = (collection, scope) => {
    if (scope.element) {
      scope.element.querySelectorAll('input').forEach((field) => {
        if(!['hidden', 'submit', 'checkbox', 'radio'].includes(field.type) && field.required) {
          collection.push(field);
        }
      });

      // URL
      scope.element.querySelectorAll('input[type="url"]').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });

      // PHONE
      scope.element.querySelectorAll('input[type="tel"]').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });

      // NUMBERS
      scope.element.querySelectorAll('input[type="number"]').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });

      // CHECKBOX
      scope.element.querySelectorAll('input[type="checkbox"]').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });

      // RADIO
      scope.element.querySelectorAll('input[type="radio"]').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });

      // TEXT AREAS
      scope.element.querySelectorAll('textarea').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });

      // SELECT FIELDS
      scope.element.querySelectorAll('select').forEach((field) => {
        if(field.required) {
          collection.push(field);
        }
      });
    }
  }
}
